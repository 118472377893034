

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearAuthInfo } from '../../reducers/authInfoSlice';

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authInfo = useSelector((state: any) => state.authInfo.data);

  useEffect(() => {
    const handleLogout = async () => {
      try {
        const refreshToken = authInfo.refresh_token;
        
        if (!refreshToken) {
        
          window.location.href = `${process.env.REACT_APP_SYSTEM_DOMAIN}/`;
          return;
        }

        const response = await fetch(`/fleet-auth-service/logout?refreshToken=${refreshToken}`, {
          method: 'POST',
        });

        if (response.ok) {
          dispatch(clearAuthInfo());
          navigate('/');
        } else {
          throw new Error('Failed to logout');
        }
      } catch (error) {
        console.error('Error logging out:', error);
      }
    };

    handleLogout();
  }, [authInfo, dispatch, navigate]);

  return <div>Logging out...</div>;
};

export default Logout;
