import { useSelector } from "react-redux"
import { Navigate, Outlet, useLocation } from "react-router-dom"

const ProtectedRoute = () => {
  const location = useLocation()
  const authInfo = useSelector((state: any) => state.authInfo)
  return authInfo?.data === null ? (
    <Navigate to="/" replace state={{ from: location }} />
  ) : (
    <Outlet />
  )
}

export default ProtectedRoute
