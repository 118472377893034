import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./App.css"
import AppRouter from "./routes"
import { Provider } from "react-redux"
import store, { persistor } from "./config/store"
import { PersistGate } from "redux-persist/integration/react"

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer
          hideProgressBar
          position="bottom-right"
          closeButton={<span />}
        />
        <AppRouter />
      </PersistGate>
    </Provider>
  )
}

export default App
