import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { jwtDecode } from "jwt-decode";
import AppHeader from "../../layouts/AppHeader";
import { useSelector } from "react-redux";
import "./App.css";

function App() {
  const getToken = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SYSTEM_DOMAIN}/guest-token`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data.token;
    } catch (error) {
      console.error("Failed to fetch token:", error);
      return null;
    }
  };

  useEffect(() => {
    const embed = async () => {
      try {
        const token = await getToken();
        if (token) {
          const mountPoint = document.getElementById("dashboard");
          if (mountPoint) {
            await embedDashboard({
              id: `${process.env.REACT_APP_SUPERSET_DASHBOARD_ID}`,
              supersetDomain : `${process.env.REACT_APP_SUPERSET_DOMAIN}`,
              mountPoint: mountPoint,
              fetchGuestToken: () => token,
              dashboardUiConfig: {
                hideTitle: true,
                hideChartControls: true,
                hideTab: true,
              },
            });
          } else {
            console.error("Mount point element not found.");
          }
        } else {
          console.error("Failed to fetch token.");
        }
      } catch (error) {
        console.error("Error embedding dashboard:", error);
      }
    };

    embed();
  }, []);

  
  return (
    <div className="App">
      <AppHeader />
      
      <Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#f1f4f8",
          }}
        >
          <Grid>
            <Box
              sx={{
                margin: "auto",
                width: "80%",
                paddingTop: "40px",
              }}
            >
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="none"
                  style={{ color: "#A2ADD0", fontSize: "14px" }}
                  href="/dashboard"
                >
                  HOME
                </Link>
                <Typography
                  color="#4191FF"
                  fontSize="14px"
                  sx={{ cursor: "pointer" }}
                >
                  FLEET ANALYTICS
                </Typography>
              </Breadcrumbs>
            </Box>
          </Grid>
          
          <Box sx={{ marginTop: "20px" }}>
            <div id="dashboard" />
          </Box>
        </Box>
      </Grid>
    </div>
  );
}

export default App;






