import { Button, TextField, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { useFormik } from "formik"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import Logo from "../../assets/images/coat_of_arms.png"
import "../../styles/login.css"

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email")
    .matches(emailRegex, "Incorrect email"),
})

const Forgotpassword = () => {
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  async function handleSubmit() {
    try {
      const response = await fetch(
        `/uaa/forgot-password?userEmail=${formik.values.email}`,
        {
          method: "POST",
        }
      )
      if (response.ok) {

      } else {

      }
    } catch (error) {
      console.error(error)
    }
  }
  const handlebacklogin = () => {
    navigate("/");
  };

  return (
    <Box className="login-container">
      <Grid container spacing={0}>
        <Grid item xs={0} sm={7} md={8} lg={8} className="front-image" />
        <Grid
          item
          xs={12}
          sm={5}
          md={4}
          lg={4}
          container
          justifyContent="center"
          alignItems="center"
          style={{ position: "relative" }}
        >

          <Box className="form">
            <img src={Logo} alt="Logo" className="logo" />
            <Typography className="subtitle">
              Enter your email below to recover your password
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <Box>
                <TextField
                  name="email"
                  label="Email"
                  placeholder="Enter the Email ID"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                />
                {formik.touched.email && formik.errors.email && (
                  <Box className="error-text">{formik.errors.email}</Box>
                )}
              </Box>

              <Box style={{ marginTop: "10px" }}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{

                    backgroundColor: "#76acdc",
                    color: "#FFFFFF",
                  }}
                >
                  Submit
                </Button>
                <Typography
                  onClick={handlebacklogin}
                  sx={{
                    marginTop: 2,
                    color: "#808080",
                    cursor: 'pointer',
                    display: 'inline-block',
                    width: '100%',
                    textAlign: 'center',
                    backgroundColor: '#FFFFFF',
                    padding: '8px 0', 
                    borderRadius: '4px', 
                    '&:hover': {
                      backgroundColor: '#b3b3b3', 
                    }
                  }}
                >
                  Back to Login
                </Typography>
              </Box>
            </form>
          </Box>

          <Box className="login-footer">
            <span>© 2024 Copyright</span>{" "}
            <span className="login-footer-link">10XTECHNOLOGIES</span>. All
            Rights Reserved
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Forgotpassword



