import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Logo from "../../assets/images/coat_of_arms.png";
import shadow from "../../assets/images/shadow.png";
import "../../styles/login.css";

const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
        .required("New Password is required")
        .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), undefined], 'Passwords must match')
        .required('Confirm Password is required')
});


const ResetPassword = () => {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const formik = useFormik({
        initialValues: {
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                const response = await fetch(`/uaa/reset-password`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        newPassword: values.newPassword,
                        confirmPassword: values.confirmPassword,
                    }),
                });
                if (response.ok) {
                    navigate("/login");
                } else {
                    console.error("Failed to reset password");
                }
            } catch (error) {
                console.error("Failed to reset password", error);
            }
        },
    });

    return (
        <Box className="login-container">
            <Grid container spacing={0}>
                <Grid item xs={0} sm={7} md={8} lg={8} className="front-image" />
                <Grid
                    item
                    xs={12}
                    sm={5}
                    md={4}
                    lg={4}
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ position: "relative" }}
                >
                    <Box
                        className="shadow-image"
                        style={{ backgroundImage: `url(${shadow})` }}
                    />
                    <Box className="form">
                        <img src={Logo} alt="Logo" className="logo" />
                        <Typography className="title">Reset your password</Typography>
                        <form onSubmit={formik.handleSubmit}>
                            <Box>
                                <TextField
                                    name="newPassword"
                                    label="New Password"
                                    placeholder="Enter the new password"
                                    type={showPassword ? "text" : "password"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.newPassword}
                                    error={
                                        formik.touched.newPassword && Boolean(formik.errors.newPassword)
                                    }
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleClickShowPassword}>
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {formik.touched.newPassword && formik.errors.newPassword && (
                                    <Box className="error-text">{formik.errors.newPassword}</Box>
                                )}
                            </Box>
                            <Box>
                                <TextField
                                    name="confirmPassword"
                                    label="Confirm New Password"
                                    placeholder="Confirm the new password"
                                    type={showPassword ? "text" : "password"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.confirmPassword}
                                    error={
                                        formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)
                                    }
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"

                                />
                                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                                    <Box className="error-text">{formik.errors.confirmPassword}</Box>
                                )}
                            </Box>
                            <Box style={{ marginTop: "10px" }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    sx={{

                                        backgroundColor: "#EB6247",
                                        color: "#FFFFFF",
                                        textDecoration: "none",
                                    }}
                                >
                                    Reset
                                </Button>
                            </Box>
                        </form>
                    </Box>
                    <Box className="login-footer">
                        <span>© 2024 Copyright</span>{" "}
                        <span className="login-footer-link">10XTECHNOLOGIES</span>. All
                        Rights Reserved
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ResetPassword;
