import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { jwtDecode } from "jwt-decode";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import AppHeader from "../../layouts/AppHeader";

const AccountSettingsForm = () => {
  const authInfo = useSelector((state: any) => state.authInfo.data);
  const token = authInfo.access_token;
  const decoded = jwtDecode(token) as { [key: string]: any };

  const Firstname = decoded.family_name;
  const Lastname = decoded.given_name;
  const Emailid = decoded.email;
  const [firstName, setFirstName] = useState(Firstname || "");
  const [lastName, setLastName] = useState(Lastname || "");
  const [email, setEmail] = useState(Emailid || "");
  const [role, setRole] = useState("Admin");
  const [image, setImage] = useState<string | null>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Changes saved!");
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh", 
          backgroundColor: "#f1f4f8",
        }}
      >
        <Grid>
          <AppHeader />
        </Grid>
        <Grid>
          <Box
            sx={{
              margin: "auto",
              width: "80%",
              paddingTop: "40px",
            }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="none"
                style={{ color: "#A2ADD0", fontSize: "14px" }}
                href="/dashboard"
              >
                HOME
              </Link>
              <Typography
                color="#4191FF"
                fontSize="14px"
                sx={{ cursor: "pointer" }}
              >
                MY ACCOUNT
              </Typography>
            </Breadcrumbs>
          </Box>
        </Grid>
        <Container maxWidth="lg" sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              marginX: "5%",
              paddingBottom:'70px'
            }}
          >
            <Box
              sx={{
                width: "100%",
                alignItems: "flex-start",
                mt: 3,
              }}
            >
              <Box
                sx={{
                  border: "3px solid #E6E6E6",
                  borderRadius: "10px",
                  background: "#fff",
                  padding: "0px 10px",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    borderBottom: "1px solid #E6E6E6",
                    padding: "15px",
                  }}
                >
                  Account Settings
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ mt: 1 }}
                  >
                    <Grid item xs={12} md={8} sx={{ mt: 2 }}>
                      <TextField
                        label="First name"
                        fullWidth
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        sx={{ my: 1, mx: 1, width: "calc(100% - 16px)" }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      <TextField
                        fullWidth
                        label="Last name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        sx={{ my: 1, mx: 1, width: "calc(100% - 16px)" }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />

                      <TextField
                        fullWidth
                        label="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{ my: 1, mx: 1, width: "calc(100% - 16px)" }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />

                      <Select
                        fullWidth
                        labelId="role-label"
                        placeholder="Choose your role"
                        value={role}
                        onChange={(e) => setRole(e.target.value as string)}
                        sx={{ my: 1, mx: 1, width: "calc(100% - 16px)" }}
                        inputProps={{
                          readOnly: true,
                        }}
                      >
                        <MenuItem value="Admin">Admin</MenuItem>
                        <MenuItem value="User">User</MenuItem>
                      </Select>

                      <Box sx={{ mt: 2 }}>
                        {/* <Button variant="contained" color="primary" type="submit">
                          Save Changes
                        </Button> */}
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Box>
          </Box>
        </Container>
        <Box
          sx={{
            backgroundColor: "#fff",
            padding: "10px",
            textAlign: "center",
            marginTop: "auto",
          }}
          className="app-footer"
        >
          © 2024 Copyright{" "}
          <span className="app-footer-link">10XTECHNOLOGIES</span>. All Rights
          Reserved
        </Box>
      </Box>
    </>
  );
};

export default AccountSettingsForm;

